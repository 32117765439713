<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="10" sm="6" md="4" lg="3" xl="3">
        <v-alert
          v-if="showError && errorOnAuthenticate"
          v-model="showError"
          type="error"
          dismissible
          >{{ authError }}</v-alert
        >
        <v-card>
          <v-card-text>
            <h1 class="text-h3">Log In</h1>
            <form @submit.prevent="authenticate">
              <v-text-field
                v-model="email"
                type="email"
                label="E-mail"
                placeholder=" "
                autocomplete="username"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="showPass ? 'visibility_off' : 'visibility'"
                :type="showPass ? 'text' : 'password'"
                label="Password"
                placeholder=" "
                hint="At least 8 characters"
                counter
                autocomplete="current-password"
                required
                @click:append="showPass = !showPass"
              ></v-text-field>
              <v-btn v-if="!isAuthenticatePending" type="submit">Sign in</v-btn>
              <v-btn disabled v-else>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Login",
  computed: {
    ...mapState("auth", ["isAuthenticatePending", "errorOnAuthenticate"]),
    authError() {
      if (this.errorOnAuthenticate == null) return "";
      switch (this.errorOnAuthenticate.message) {
        case "Failed to fetch":
          return "Could not Log In: Connection Timed out";
        default:
          return "";
      }
    },
  },
  methods: {
    authenticate() {
      this.$store
        .dispatch("auth/authenticate", {
          strategy: "local",
          email: this.email,
          password: this.password,
        })
        .then(() => {
          const { redirect } = this.$route.query;
          if (redirect) {
            this.$router.push(redirect);
          } else {
            this.$router.push({ name: "ScenarioCases" });
          }
        })
        .catch((err) => {
          console.error("Auth Error", err);
          this.showError = true;
          // Do nothing
        });
    },
  },
  watch: {},
  mounted() {
    const { token } = this.$route.query;
    if (token != null) {
      this.$store
        .dispatch("auth/authenticate", {
          strategy: "auth0",
          accessToken: token,
        })
        .then(() => {
          const { redirect } = this.$route.query;
          if (redirect) {
            this.$router.push(redirect);
          } else {
            this.$router.push({ name: "ScenarioCases" });
          }
        })
        .catch(() => {
          this.showError = true;
          // Do nothing
        });
    }
  },
  data() {
    return {
      email: "",
      password: "",
      valid: null,
      showPass: false,
      showError: false,
    };
  },
};
</script>
